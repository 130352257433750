// Generated by Framer (1249b65)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cz6wyxhKu"];

const variantClassNames = {cz6wyxhKu: "framer-v-et2hfc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cz6wyxhKu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rMQee", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-et2hfc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cz6wyxhKu"} ref={ref} style={{...style}}><SVG className={"framer-iytm69"} data-framer-name={"Line_6"} fill={"black"} intrinsicHeight={1070} intrinsicWidth={1} layoutDependency={layoutDependency} layoutId={"Wt5bzlSit"} svg={"<svg width=\"1\" height=\"1070\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"#5D91C7\" stroke-linecap=\"round\" d=\"M.5 1.198V1069.5\"/></svg>"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rMQee [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rMQee .framer-i4ahv1 { display: block; }", ".framer-rMQee .framer-et2hfc { height: 1691px; overflow: hidden; position: relative; width: 2px; }", ".framer-rMQee .framer-iytm69 { aspect-ratio: 0.0009345794392523365 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 2140px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1691
 * @framerIntrinsicWidth 2
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerR5WtVjT24: React.ComponentType<Props> = withCSS(Component, css, "framer-rMQee") as typeof Component;
export default FramerR5WtVjT24;

FramerR5WtVjT24.displayName = "Line_6";

FramerR5WtVjT24.defaultProps = {height: 1691, width: 2};

addFonts(FramerR5WtVjT24, [])